<main class="layout_container">
  <header class="header-top animation">
    <div class="header-link-div">
      <div>
      </div>
      <hr class="hr-class">
    </div>
  </header>

  <div class="section animation about-me-container">
    <div class="about-me-picture">
      <img class="profile-img" src="assets/images/rolandodev_image.JPEG">
    </div>
    <div class="about-me-paragraph">
      <h1 class="about-me-h1-title">
        <span class="page-title">Hello, my name is Rolando Cepeda</span>
        <span class="page-title title-animation" #whoami> and I'm a {{whoAmI}}</span>
      </h1>
      <!-- <h2></h2> -->
      <div class="about-me-tldr">
        <p class="intro-text"><strong class="page-title-bold">TL;DR</strong> I love learning, my wife, coding, coffee,
          keyboards, and all things League of Legends.</p>
        <p class="intro-text">I am a small-town kid with big dreams. I have a degree in Engineering Physics but have a
          passion for software development. I have had the pleasure of working alongside some incredible engineers,
          designers, business analysts, and product owners. I am passionate about code standards, DevOps, and reactive
          Javascript frameworks (Angular is my jam).</p>
      </div>
    </div>
  </div>

  <div class="section skill-container animation">
    <div>
      <h1>
        Skills
      </h1>
      <p class="paragraph">While working as a Software Engineer for the Department of Defense (DoD) I have learned and
        gained a solid understanding of web development standards, the importance of reusability/scalability in large
        enterprise applications, and how user experience means everything. If you would like to know more about my
        skills, take a look at my <a style="color: #c75139;" href="assets/files/rolando_cepeda_resume.pdf" download>
          resume
        </a></p>
    </div>
    <div class="chip-container">
      <span class="chip" *ngFor="let skill of skills">{{skill}}</span>
    </div>
  </div>

  <div class="section animation" style="padding-bottom: 12rem;">
    <h1>
      My Work
    </h1>
    <div class="carousel">
      <mat-card class="item" *ngFor="let project of projects" (click)="openUrl(project.websiteURL)">
          <mat-card-header>
            <mat-card-title>{{project.name}}</mat-card-title>
            <mat-card-subtitle>{{project.company}}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <img style="width: 100%;" src="{{project.imageURL}}">
          </mat-card-content>
          <mat-card-actions class="tags">
            <mat-chip-list>
              <mat-chip *ngFor="let tag of project.tags">{{tag}}</mat-chip>
            </mat-chip-list>
          </mat-card-actions>
      </mat-card>
    </div>
  </div>


</main>

<div class="bottom-page-div animation">
  <div class="layout_container">
    <div>
      <p class="title ">Have an Idea? Let's Build it.</p>

      <p class="paragraph">Feel free to reach out if you’re in need of a Developer. Whether that means you need a
        website, web app, or you’re looking to fill a full-time position. I’m always open to a conversation.</p>

      <a href="mailto:contact@rolandocepeda.dev" class="email-link">
        contact@rolandocepeda.dev
      </a>
    </div>
  </div>
</div>
