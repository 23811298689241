import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Project } from './project';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
  title = 'rolandocepedadev';
  whoAmI = '';
  skills = ['Angular', 'React', 'Java', 'Python', 'Dotnet/C#',
    'Node', 'Docker', 'Ansible', 'Openshift', 'Kubernetes', 'Terraform', 'Helm',
    'Jenkins', 'Postgres', 'Elastic Search', 'GeoServer', 'OpenLayers', 'Unit testing', 'TDD', 'Agile'];
  projects: Project[] = [
    {
      name: 'rolandocepeda.dev',
      company: 'Personal',
      description: '',
      timeline: '',
      imageURL: 'assets/images/cicd_pipeline_infograph.png',
      websiteURL: 'https://gitlab.com/rolandocepeda-dev/rolandocepeda-dev-light/-/pipelines',
      tags: ['DevOps', 'Angular', 'CI/CD', 'AWS']
    },
    {
      name: 'Collide',
      company: 'Mantech | JCC2',
      description: '',
      timeline: '',
      imageURL: 'assets/images/collide.png',
      tags: ['Angular', 'NodeJS', 'CI/CD', 'TDD', 'Agile', 'Postgres']
    },
    {
      name: 'CCAT',
      company: 'Mantech | Unified Platform',
      description: '',
      timeline: '',
      imageURL: 'assets/images/rocketchat.png',
      tags: ['Openshift', 'CI/CD', 'MeteorJS', 'MongoDB']
    },
    {
      name: 'ACEIOS',
      company: 'CACI',
      description: '',
      timeline: '',
      imageURL: 'assets/images/caci.jpeg',
      tags: ['Angular', 'JavaEE', 'Bash', 'GeoServer', 'Openlayers']
    }
  ];

  constructor() { }

  ngAfterViewInit(): void {
    this.animateWhoAmI();
  }

  openUrl(url: string): void {
    if (url) {
      window.open(url);
    }
  }

  animateWhoAmI(): void {
    document.addEventListener('DOMContentLoaded', (event) => {
      // array with texts to type in typewriter
      const dataText = ['Software Engineer.', 'DevOps Engineer.', 'Keyboard Enthusiast.'];

      const animateText = () => dataText.forEach((text, index) => {
        setTimeout(() => {
          this.whoAmI = '';
          text.split('').forEach((c, j) => {
            return setTimeout(() => {
              this.whoAmI = this.whoAmI + c;
              if (j === text.length - 1) {
                setTimeout(() => {
                  const returnTitle = this.whoAmI.split('');
                  return text.split('').forEach((char, k) => {
                    return setTimeout(() => {
                      returnTitle.pop();
                      this.whoAmI = returnTitle.join('');
                      if (index === dataText.length - 1 && k === text.length - 1) {
                        setTimeout(() => {
                          animateText();
                        }, 1000);
                      }
                    }, k * 150);
                  });
                }, 1000);
              }
            }, j * 150);
          });
        }, index * 8000);
      });
      animateText();
    });
  }
}
